<template>
  <div
    data-allow-mismatch="children"
    class="provider-form-feedback-interview"
  >
    <ClientOnly>
      <BlockSwiperSlot
        v-if="status === 'success'"
        :show-pagination="true"
        :slides="steps"
        :has-controls="true"
        :has-forms="true"
        :show-swiper-on-mobile="true"
        :swiper-options="JSON.stringify({
          grabCursor: false,
          simulateTouch: false,
          touchStartPreventDefault: true,
          spaceBetween: 30,
        })"
        :controls-text-next="nextText"
        :controls-text-prev="prevText"
        :submit-callback="onSubmit"
        :success-redirect="successRedirect?.cached_url"
        :error-message="errorMessage"
        :submit-text="submitText"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
const props = defineProps({
    steps: {
        type: Array,
        default: () => [],
    },

    nextText: {
        type: String,
        default: 'Next',
    },

    prevText: {
        type: String,
        default: 'Previous',
    },

    submitText: {
        type: String,
        default: 'Submit',
    },

    errorMessage: {
        type: String,
        default: null,
    },

    successRedirect: {
        type: Object,
        default: null,
    },
});

const strapiClient = useStrapiClient();
const userHash = useCookie('user_hash');
const recruiter = inject('recruiter', {});
const { steps } = toRefs(props);

// Get stored feedback
const { data, error, status } = useLazyAsyncData(
    'feedbacks/getFeedbacks',
    () => strapiClient('/feedbacks/getOneByHash', {
        method: 'GET',
        params: {
            hash: userHash.value,
        },
    }),
    {
        transform: (res) => res.map((item) => ({
            questionIdentifier: item.questionIdentifier,
            question: item.question,
            comment: item.comment,
            answer: item.answer,
        })),
    },
);

// Prefill feedback based on stored data
watch(() => data.value, (newValue) => {
    if (newValue) {
        if (isArrayNotEmpty(newValue)) {
            newValue.forEach((question) => {
                const currentStep = steps.value.find(
                    (step) => parseInt(
                        step.questionIdentifier,
                        10,
                    ) === parseInt(
                        question.questionIdentifier,
                        10,
                    ),
                );

                if (isArrayNotEmpty(currentStep.fields)) {
                    currentStep.fields.forEach((field) => {
                        if (field.name === 'answer' || field.name === 'comment') {
                            // eslint-disable-next-line no-param-reassign
                            field.initialValue = `${question[field.name]}`;
                        }
                    });
                }
            });
        }
    }
}, { immediate: true });

if (error.value) {
    useSentryError(error.value, false);
}

// Submit Feedback
const onSubmit = async (dataToSubmit) => {
    const filteredQuestions = [];
    Object.entries(dataToSubmit).forEach((entry) => {
        const [key, value] = entry;
        if (value.answer || value.comment) {
            filteredQuestions.push({
                hash: userHash.value,
                recruiter: recruiter.value.id,
                questionIdentifier: key,
                answer: value.answer,
                message: value.comment,
            });
        }
    });

    await Promise.all(filteredQuestions.map((item) => strapiClient('/feedbacks/upsertOneWithHash', {
        method: 'GET',
        params: item,
    })));
};
</script>
